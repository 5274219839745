export enum GeofenceType {
  Circle = 'Circle',
  Polygon = 'Polygon'
}

export interface IGpsGeofence {
  _id: string;
  owner?: string;
  title: string;
  description: string;
  geofenceType: GeofenceType;
  location: {coordinates: number[] | number[][][]}; // can be array of array of arrays of numbers for polygon
  radius: number;
}
