import { IPeople } from '../people/people.interface';
import { NgSelectDataItem } from './ng-select-data-item';
import { OrganizationGroup } from '../organization/dto/organization-group.model';

export class SelectUtils {

  public static generateDataSourceForPeople(owners: IPeople[]): NgSelectDataItem[] {
    if (owners) {
      return owners.map(person => {
        return {
          value: person._id,
          label: `${person.firstName} ${person.middleName} ${person.lastName}`
        };
      });
    } else {
      return [];
    }
  }

  public static generateDataSourceForOrganizationGroup(groups: OrganizationGroup[]) {
    if (!groups) {
      return [];
    }

    return groups.map(group => {
      return {
        value: group._id,
        label: group.title
      };
    });
  }
}
